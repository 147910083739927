/* ==========================================================================
	Perso Lydia
	========================================================================== */
  .footer-accueil{
    background-color: rgba($color: $brand-primary, $alpha: 0.65);
    .container,p,a,strong,small,address, h1, .h1{
      color: #fff;
    }
  }


.fa-instagram{
  background:linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
  border-radius: 35px;
}

  body {
    // background: url(../images/homeBG.jpg) no-repeat center fixed;
    background-size: cover;
    // background-color: $body-bg;
    font-family: $font-family-sans-serif;
    letter-spacing: 0.03rem;
  }

  .main {
    background-color: $body-bg;
    padding-top: 0px;
    min-height: 50vh;
  }

  .row-reverse{
    flex-direction: row-reverse;
  }

  /* ==========================================================================
POPUP
	========================================================================== */

  .section-actu {
    margin: 30px auto;
    width: 100%;
    max-width: 1060px;

    @media(min-width: $screen-md-min) {
      padding: 0 30px;
    }
  }

  /* ==========================================================================
	MAP
	========================================================================== */

  .embed-responsive-map {
    padding-bottom: 40rem;
  }

  .block-address {

    @media(min-width: $screen-md-min) {
      position: absolute;
      top: 3rem;
      right: 3rem;
      width: 25%;
    }
  }

  /* ==========================================================================
LABEL + BTN
	========================================================================== */

  .label {
    font-size: 80%;
  }


  /* ==========================================================================
BACKGROUND
	========================================================================== */

  @mixin background($imgpath: "",
    $overlay: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0))) {
    background: $overlay, url($imgpath) center center no-repeat;
    background-size: cover, cover;
  }

  @mixin background2($imgpath: "", $color: rgba(0, 0, 0, 0), $opacity: 0) {
    background: linear-gradient(rgba($color, $opacity), rgba($color, $opacity)),
      url($imgpath) center center fixed;
    background-size: cover, cover;
  }

  .bg-2 {
    @include background2("../images/presentation/bg-2.jpg", #000, 0.3);
  }

  .bg-20 {
        background: url(../images/charte/bg-20.jpg) no-repeat center center;
        background-size: cover;
  }

  .bg-21 {
    background: url(../images/charte/bg-21.jpg) no-repeat center center;
    background-size: cover;
}

  .bg-3 {
    @include background2("../images/presentation/bg-3.jpg", #000, 0.3);
  }

  .bg-4 {
    @include background2("../images/presentation/bg-4.jpg", #000, 0.3);
  }

  .bg-5 {
    @include background2("../images/restaurant/bg-5.jpg", #000, 0.3);
  }

  .bg-6 {
    @include background2("../images/restaurant/bg-6.jpg", #000, 0.3);
  }


  .bg-9 {
    @include background2("../images/hotel/02.jpg", #000, 0.3);
  }

  .bg-10 {
    @include background2("../images/hotel/bg-10.jpg", #000, 0.3);
  }

  .bg-gradient-1 {
    @include background("",
      linear-gradient(45deg, $brand-primary, $brand-success));
  }

  .bg-marielouise {
    @include background2("../images/charte/bg-1.jpg", #000, 0.3);
    border: 3vw solid rgb(231, 231, 231);
  }

  .bg-marielouise-2 {
    @include background2("../images/restaurant/bg-2.jpg", #000, 0.3);
    border: 3vw solid rgb(231, 231, 231);
  }

  .bg-marielouise-3 {
    @include background2("../images/hotel/bg-1.jpg", #000, 0.3);
    border: 3vw solid rgb(231, 231, 231);
  }

  .bg-chart{
    background-color: $brand-chart !important;
  }

  .bg-second-chart{
    background-color: $brand-second-chart !important;
  }

  .bg-info{
    background-color: $brand-info;
  }

  .bg-white {
    background-color: white;
  }

  .bg-col-6{
    background: linear-gradient(rgba(0,0,0,.03),rgba(0,0,0,.03)),url(../images/charte/03.jpg) center center no-repeat;
    background-size: cover,cover;
    min-height: 768px;
  }

  /* ==========================================================================
FONTS
	========================================================================== */

  //fonts

  .font-title {
    font-family: $font-title !important;
  }

  .font-small {
    font-family: $font-small !important;
  }

  /* ==========================================================================
QUAND SITE NOIR - DIFFICILE D'AVOIR UN DROPDOWN LISSIBLE
	========================================================================== */

  // .nav-base .list-group-item>a:hover {
  //   background-color: #fff;
  //   color: $brand-chart;
  // }

  // .nav-base .list-group-item>a {
  //    color: #fff;
  // }

/* ==========================================================================
BORDER
	========================================================================== */

  .border {
    border: 1px solid $gray-darker !important;
  }

  .border-0 {
    border: 0 !important;
  }

  .border-top {
    border-top: 1px solid $gray-darker !important;
  }

  .border-top-0 {
    border-top: 0 !important;
  }

  .border-end {
    border-right: 1px solid $gray-darker !important;
  }

  .border-end-0 {
    border-right: 0 !important;
  }

  .border-bottom {
    border-bottom: 1px solid $gray-darker !important;
  }

  .border-bottom-0 {
    border-bottom: 0 !important;
  }

  .border-start {
    border-left: 1px solid $gray-darker !important;
  }

  .border-start-0 {
    border-left: 0 !important;
  }

  .border-primary {
    border-color: $brand-primary !important;
  }

  .border-secondary {
    border-color: $brand-second !important;
  }

  .border-success {
    border-color: $brand-success !important;
  }

  .border-info {
    border-color: $brand-info !important;
  }

  .border-warning {
    border-color: $brand-warning !important;
  }

  .border-danger {
    border-color: $brand-danger !important;
  }

  .border-chart{
    border-color: $brand-chart important;
  }

  .border-second-chart{
    border-color: $brand-second-chart important;
  }

  .border-light {
    border-color: $gray-light !important;
  }

  .border-dark {
    border-color: $gray-dark !important;
  }

  .border-white {
    border-color: #fff !important;
  }

  .border-0 {
    border-width: 0 !important;
  }

  .border-1 {
    border-width: 1px !important;
  }

  .border-2 {
    border-width: 2px !important;
  }

  .border-3 {
    border-width: 3px !important;
  }

  .border-4 {
    border-width: 4px !important;
  }

  .border-5 {
    border-width: 5px !important;
  }


   /* ==========================================================================
MODIFICATION TAILLE LOGO HEADER pour avoir une navbar ok
	========================================================================== */

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    .header-anim-1__logo{
      width: 10rem;
    }
  }

 /* ==========================================================================
NAVBAR
	========================================================================== */

  // Navbar Si centrage elements sur deux lignes


  .navbar-nav {
    //@extend .navbar-right; // pour aligner � droite.
    //text-align: center;
    font-family: $font-family-base !important;

    // taille police menu tablette 768
    font-size: $font-size-base;
    font-weight: 500;

  // taille police menu portable 1024
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
      font-size: 13px;
    }

    @media(min-width: $grid-float-breakpoint) {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      float: none;

    }

      >li {

        >a {
          line-height: 1.6;
          position: relative;

          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            right: 50%;
            height: 0;
            background-color: $brand-primary;
            opacity: 0;
            transition: all ease-in-out .3s;
          }
        }

        &:hover>a::after,
        &:active>a::after,
        &:focus>a::after,
        &.active>a::after {
          left: 16px;
          right: 16px;
          height: 3px;
          opacity: 1;
        }
      }
    }

    .index-nav {
      @extend .pull-right;
      display: inline-block;
      line-height: $navbar-height;
      padding-right: 10px;
      color: $navbar-default-toggle-icon-bar-bg;
      text-transform: uppercase;
      font-weight: 700;

      @media (min-width: $grid-float-breakpoint) {
        display: none;
      }
    }

  /* ==========================================================================
	FLEX
	========================================================================== */

  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: $screen-xs-max) {
      display: inline !important;
    }
  }

  .flex-end {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    @media (max-width: $screen-xs-max) {
      display: inline !important;
    }
  }

  /* ==========================================================================
	LINE HEIGHT
	========================================================================== */

  .lh-1 {
    line-height: 1 !important;
  }

  .lh-sm {
    line-height: 1.25 !important;
  }

  .lh-base {
    line-height: 1.5 !important;
  }

  .lh-lg {
    line-height: 2 !important;
  }

  .divider-img {
    // background: url(.../images/charte/divider.png) center no-repeat;
    height: 20px;
    max-width: 400px;
    margin: 10px auto;
  }


  /* ==========================================================================
	OVERFLOW
	========================================================================== */
  .overflow-auto {
    overflow: auto !important;
  }

  .overflow-hidden {
    overflow: hidden !important;
  }

  .overflow-visible {
    overflow: visible !important;
  }

  .overflow-scroll {
    overflow: scroll !important;
  }

  /* ==========================================================================
	POSITION
	========================================================================== */

  .position-static {
    position: static !important;
  }

  .position-relative {
    position: relative !important;
  }

  .position-absolute {
    position: absolute !important;
  }

  .position-fixed {
    position: fixed !important;
  }

  .position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .top-0 {
    top: 0 !important;
  }

  .top-50 {
    top: 50% !important;
  }

  .top-100 {
    top: 100% !important;
  }

  .bottom-0 {
    bottom: 0 !important;
  }

  .bottom-50 {
    bottom: 50% !important;
  }

  .bottom-100 {
    bottom: 100% !important;
  }

  .start-0 {
    left: 0 !important;
  }

  .start-50 {
    left: 50% !important;
  }

  .start-100 {
    left: 100% !important;
  }

  .end-0 {
    right: 0 !important;
  }

  .end-50 {
    right: 50% !important;
  }

  .end-100 {
    right: 100% !important;
  }

  h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {

  small {
    display: block;
    padding-top: 1rem;
  }
}

.page-header-top {
  margin: 50px 0;
  // position: relative;
  // padding-bottom: 3rem;

  //  &:after{
  // 	content : '';
  // 	position: absolute;
  // 	bottom: 0;
  // 	left:0;
  // 	width: 3rem;
  // 	height: 3px;
  // 	background-color: white;
  // }

  >h1,
  >.h1 {
    margin: 0;
  }
}

// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
// .h1,
// .h2,
// .h3,
// .h4,
// .h5,
// .h6 {
//   @media (max-width: $screen-sm-max) {
//     font-family: $font-family-base;
//     font-weight: 900 !important;
//   }
// }

/* ==========================================================================
	TEXTE
	========================================================================== */

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  color: inherit !important;
}

.text-white {
  color: #fff !important;
}

.text-chart{
color: $brand-chart !important;
}

.text-second-chart{
color: $brand-second-chart !important;
}

@media (min-width: $screen-md-min) {
  .figure__compo-1 {
    position: relative;
    @include padding(1rem);
    height: 40rem;
    max-height: 100%;
    width: 40rem;
    max-width: 100%;

    > img {
      position: absolute;
      object-fit: cover;
    }

    > img:nth-child(1) {
      top: 0;
      left: 0;
      width: 70%;
      height: 55%;
    }

    > img:nth-child(2) {
      bottom: 0;
      right: 0;
      width: 70%;
      height: 55%;
    }
  }
}

// RESET TABLE CAR BUG SANS RAISON

// .table{
//   background-color: transparent;
//   max-width: 900px;

//   td:nth-child(2){
//   width: 100px;
//   // text-align: right;
//   }

//   td:nth-child(3){
//   width: 100px;
//   // text-align: right;
//   }

//   i{
//     display: block;
//     margin-top: .5rem;
//     font-size: 14px;
//     line-height: 1;
//     color:$gray-light;
//     line-height: 1.3;
//   }
// }

// .table td.td-width-2 {
//   width: 200px;
// }

//Vid�o avec elements qui passent dessus

#background-video-2{
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  }

  .logo-accueil img{
    max-height: 300px;
  }

  .gap1{
      gap:50px
  }

  .logo-header-1{

    img{
      max-height:200px;
      margin:30px auto;

      @media (max-width:$screen-sm-min) {
      max-height:200px;
      }

  }
}

.logo1{

  @media (max-width:$screen-xs-min) {
    max-width: 250px;
  }

}

.logo2{
  @media (max-width:$screen-xs-min) {
    margin-top: 50px;
    }
}

.logo-detail{
    max-width: 150px;
}

.logo-detail-2{
  max-width: 100px;
}

// Perso Niko

.img-cover {
  position: relative;
  display: block;
  width: 100%;
  height: 30rem;
  object-fit: cover;
}

.caption-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    180deg,
    rgba(black, 0) 20%,
    rgba(black, 0.8) 100%
  );
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &--animated {
    background-color: transparent;
    overflow: hidden;
    transition: background-color ease 0.5s;

    .animated-panel {
      height: 40%;
      transition: height ease 0.4s;
    }

    .animated-content {
      opacity: 0;
      position: relative;
      top: 5rem;
      transition: opacity ease 0.4s, top ease 0.4s;
      transition-delay: 0.2s;
    }

    .btn-box {
      position: absolute;
      bottom: -5rem;
      left: 0;
      right: 0;
      @include padding(2rem);
      opacity: 0;
      transition: bottom ease 0.4s, opacity ease 0.5s;
    }

    &:hover {
      background-color: rgba(black, 0.5);

      .animated-panel {
        height: 100%;
      }

      .animated-content {
        opacity: 1;
        top: 0;
      }

      .btn-box {
        bottom: 0;
        opacity: 1;
      }
    }
  }
}

.decal-left {
  @media (min-width: $screen-md-min) {
    margin-left: -20%;
  }
}

@media (min-width: $screen-md-min) {
  .figure__compo-1 {
    position: relative;
    @include padding(1rem);
    height: 40rem;
    max-height: 100%;
    width: 40rem;
    max-width: 100%;

    > img {
      position: absolute;
      object-fit: cover;
    }

    > img:nth-child(1) {
      top: 0;
      left: 0;
      width: 70%;
      height: 55%;
    }

    > img:nth-child(2) {
      bottom: 0;
      right: 0;
      width: 70%;
      height: 55%;
    }
  }
}

.bg-gradient{
  background: linear-gradient(132deg, #f8f8f8, #646567);
  background-size: 400% 400%;
  animation: BackgroundGradient 30s ease infinite;
}

@keyframes BackgroundGradient {
  0% {background-position: 0% 50%;}
  50% {background-position: 100% 50%;}
  100% {background-position: 0% 50%;}
}

.h-10{
  height: 6rem;
  min-height: 6rem;
}
